import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { HomePageComponent } from "./components/HomePage";
import { PrivacyPolicy } from "./components/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePageComponent />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
