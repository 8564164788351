import emailjs from "emailjs-com";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./email-dialog.css";
import { toast, ToastContainer } from "react-toastify";

export type EmailDialogProps = {
  isOpen: boolean;
  submitButtonText: string;
  onClose: () => void;
};

export const EmailDialog: React.FC<EmailDialogProps> = ({
  isOpen,
  submitButtonText,
  onClose,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // Email validation function
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Send email function
  const sendEmail = async () => {
    if (!validateEmail(email)) {
      setIsEmailValid(false);
      return;
    }

    setIsEmailValid(true);
    setIsSubmitting(true);

    try {
      // Replace these with your EmailJS service and template IDs
      const serviceId = "service_ypdiuud";
      const templateId = "template_kxue6yo";
      const userId = "LW1pP4nSEye0ZLK1t";

      // const emailParams = {
      //   customer_email: email,
      //   message: `User with email ${email} wants early access to Quilt`
      //   // Add any other parameters here
      // };

      const emailParams = {
        customer_email: email,
        message: `User with email ${email} wants to delete their account`,
        // Add any other parameters here
      };

      await emailjs.send(serviceId, templateId, emailParams, userId);
      toast.success("Your account data deletion request has been received");
      setEmail("");
    } catch (error) {
      setErrorMessage("Failed to send email. Please try again later.");
    } finally {
      setIsSubmitting(false);
      onClose(); // Close the modal after sending
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Enter your email address</h2>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className={!isEmailValid ? "error" : ""}
        />
        {!isEmailValid && (
          <p className="error-text">Please enter a valid email address.</p>
        )}
        {errorMessage && <p className="error-text">{errorMessage}</p>}

        <div className="modal-actions">
          <button onClick={sendEmail} disabled={isSubmitting}>
            {isSubmitting ? "..." : submitButtonText}
          </button>
          <button onClick={onClose}>Cancel</button>

          <EmailDialog
            isOpen={isDialogOpen}
            submitButtonText="Send"
            onClose={() => setIsDialogOpen(false)}
          />
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};
