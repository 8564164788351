import { useState } from "react";
import { EmailDialog } from "./EmailDialog";

export const PrivacyPolicy: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleFormClick = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <EmailDialog
        isOpen={isDialogOpen}
        submitButtonText="Send"
        onClose={() => setIsDialogOpen(false)}
      />
      <div className="page-wrapper inner-page">
        <header>
          <div className="d-flex align-items-center">
            <div className="logo">
              <a href="/">
                <img src="assets/images/logo.svg" alt="Quilt" />
              </a>
            </div>
            <div className="header-right">
              <div className="d-flex justify-content-end align-items-center">
                {/* Optional language switcher and Get Started button can go here */}
              </div>
            </div>
          </div>
        </header>

        <div className="inner-banner">
          <div className="banner-desc">
            <h1>Privacy Policy</h1>
          </div>
        </div>

        <div className="pg-content">
          <div className="container">
            <p>
              Welcome to Quilt, a video game-based platform for mental and
              behavioral health. At Quilt Technologies Inc., We are committed to
              protecting Your privacy and ensuring the security of Your personal
              information. This Privacy Policy outlines how We collect, use, and
              share Your data when You use Our mobile app, Quilt: Games & Health
              Tracker (Quilt app). By accessing or using the Quilt app, You
              consent to the practices described in this Privacy Policy.
            </p>

            <h2>Interpretation and Definitions:</h2>

            <h3>Interpretation:</h3>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>

            <h3>Definitions:</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <strong>Account:</strong> A unique account created for You to
                access our Service.
              </li>
              <li>
                <strong>Application:</strong> Quilt: Games & Health Tracker, the
                mobile application software provided by the Company.
              </li>
              <li>
                <strong>Company:</strong> Quilt Technologies Inc., referred to
                as "We", "Us" or "Our".
              </li>
              <li>
                <strong>Country:</strong> Delaware, United States.
              </li>
              <li>
                <strong>Device:</strong> Any device that can access the Service,
                such as a computer or mobile device.
              </li>
              <li>
                <strong>Personal Data:</strong> Information that relates to an
                identified or identifiable individual.
              </li>
              <li>
                <strong>Service:</strong> Refers to the Application.
              </li>
              <li>
                <strong>Service Provider:</strong> Third parties processing data
                on behalf of the Company.
              </li>
              <li>
                <strong>Usage Data:</strong> Data collected automatically,
                either generated by the use of the Service or from the Service
                infrastructure itself.
              </li>
              <li>
                <strong>You:</strong> The individual or legal entity using the
                Service.
              </li>
            </ul>

            <h3>1. Information We Collect:</h3>
            <p>Quilt collects the following types of data:</p>
            <p>
              <strong>Personal Data:</strong> We may ask for personally
              identifiable information, including but not limited to:
            </p>
            <ol>
              <li>Email address</li>
              <li>Name</li>
              <li>Sex</li>
              <li>Date of Birth</li>
              <li>Phone number</li>
              <li>Usage Data</li>
            </ol>

            <p>
              <strong>Usage Data:</strong> Usage Data is collected automatically
              when using the Service. This includes:
            </p>
            <ol>
              <li>App usage statistics</li>
              <li>Game interaction data</li>
              <li>General device information</li>
            </ol>

            <h3>How We Use Your Data:</h3>
            <p>We use the collected data for the following purposes:</p>
            <ol>
              <li>To provide and maintain our Service</li>
              <li>To manage Your Account</li>
              <li>To personalize Your experience</li>
              <li>To monitor and improve Our programs</li>
              <li>To send notifications and updates</li>
              <li>For research and development purposes</li>
              <li>To comply with legal obligations</li>
            </ol>

            <h3>3. Sharing Your Information:</h3>
            <p>We may share Your data under the following circumstances:</p>
            <ol>
              <li>With your explicit consent for mental health treatment</li>
              <li>In response to legal requests</li>
              <li>With affiliates under common control</li>
            </ol>

            <h3>4. Retention of Your Personal Data:</h3>
            <p>
              We retain Personal Data for as long as necessary to fulfill the
              purposes outlined in this Privacy Policy, and for compliance with
              legal obligations.
            </p>

            <h3>5. Transfer of Your Personal Data:</h3>
            <p>
              Your data may be processed at Our operating offices and
              transferred to locations with different data protection laws. By
              providing Your data, You consent to this transfer.
            </p>

            <h3>6. Disclosure of Your Personal Data:</h3>
            <p>
              We may disclose Personal Data for business transactions, law
              enforcement, or other legal requirements.
            </p>

            <h3>7. Data Security:</h3>
            <p>
              We take data security seriously and employ industry-standard
              measures to protect Your data.
            </p>

            <h3>8. Your Choices:</h3>
            <p>You have the right to:</p>
            <ol>
              <li>Access and correct your personal information</li>
              <li>Withdraw consent for data processing</li>
              <li>Delete your account and data</li>
              <li>Opt out of promotional communications</li>
            </ol>

            <p>
              You can request account deletion{" "}
              <a href="#" className="form-click" onClick={handleFormClick}>
                here
              </a>
              .
            </p>

            <h3>9. Contact Us:</h3>
            <p>If You have any questions, contact us at:</p>
            <p>Company Name: Quilt Technologies Inc.</p>
            <p>Address: 16192 Coastal Highway, Lewes, Delaware 19958</p>
            <p>Email: contact@q-u-i-l-t.com</p>

            <h3>10. Changes to this Privacy Policy:</h3>
            <p>
              We may update this Privacy Policy, and we will notify You of any
              changes.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
