import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmailDialog } from "./EmailDialog";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com";
import "react-toastify/dist/ReactToastify.css";
import "./home-page.css";

export const HomePageComponent: React.FC = () => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState("");

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault(); // Prevent form submission (if using a form)

    if (!email) {
      toast.error("Email is required");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    // Show success toast if email is valid
    const serviceId = "service_ypdiuud";
    const templateId = "template_p86t4gl";
    const userId = "LW1pP4nSEye0ZLK1t";

    // const emailParams = {
    //   customer_email: email,
    //   message: `User with email ${email} wants early access to Quilt`
    //   // Add any other parameters here
    // };

    const emailParams = {
      customer_email: email,
      message: `User with email ${email} wants early access to Quilt`,
      // Add any other parameters here
    };

    await emailjs.send(serviceId, templateId, emailParams, userId);
    toast.success("Thank you for joining Quilt ! You're on the waiting list, and we're excited to have you.");
    setEmail("");
    // Handle further logic here (e.g., sending to an API)
  };

  const handleSubscribe = () => {
    setIsDialogOpen(true);
  };

  const handlePrivacyPolicyClick = () => {
    navigate("/privacy-policy");
  };

  return (
    <>
      <EmailDialog
        isOpen={isDialogOpen}
        submitButtonText="Get early acess"
        onClose={() => setIsDialogOpen(false)}
      />
      <div className="page-wrapper">
        <header>
          <div className="d-flex align-items-center">
            <div className="logo">
              <a href="/">
                <img src="assets/images/logo.svg" alt="Quilt" />
              </a>
            </div>
            <div className="header-right">
              <div className="d-flex justify-content-end align-items-center">
                {/* Uncomment if language switcher or button is needed */}
                {/* <div className="language-swither">
                <select>
                  <option>En</option>
                </select>
              </div>
              <a href="#" className="white-btn with-arrow">Get Started</a> */}
              </div>
            </div>
          </div>
        </header>
        <div className="hero-section">
          <div className="d-flex align-items-center sm-block">
            <div className="hero-img">
              <img src="assets/images/hero-img.png" alt="Quilt" />
            </div>
            <div className="hero-desc">
              <h1>Quilt: Feel Better on Demand</h1>
              <p>
                A platform to help you feel better wherever you are, whenever
                you need it, in whatever way is most helpful to you.
              </p>
              <p>
                Quilt is a generative AI-powered platform that helps you create
                and discover quick and personalized content to support you
                during moments of stress, anxiety, insomnia, low mood, and other
                challenges you may face.
              </p>
              {/* <p>
                <a
                  onClick={handleSubscribe}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Click here
                </a>{" "}
                to get exclusive early access to the Quilt app.
              </p> */}
              <div className="app-links">
                <b style={{ fontSize: "22px" }}>Join the Waiting List</b>
                <p style={{ marginTop: "2px" }}>
                  Sign up to secure your spot on the list
                </p>
                <div className="signup-box">
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    className="email-input"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <button onClick={handleSubmit} className="signup-button">
                    Sign Up Now
                  </button>
                </div>
                <ToastContainer />
                {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                {/* <a
                  href="https://play.google.com/store/apps/details?id=com.QWLT.Quilt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/images/play-store.svg" alt="Quilt" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/quilt-feel-better-on-demand/id6462470118"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/images/app-store.svg" alt="Quilt" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <footer className="d-flex align-items-center">
          <div className="d-flex align-items-center w-100 md-block">
            <div className="footer-right d-flex align-items-center justify-content-end sm-block">
              <div className="footer-links">
                <a
                  href="mailto:contact@quilt.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact@q-u-i-l-t.com
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={handlePrivacyPolicyClick}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="copyright">
              <div className="d-flex align-items-center sm-block">
                <p>© 2024 by Quilt Technologies Inc.</p>
                <p>2024 all rights reserved</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
